import styled, { css } from 'styled-components';
import { ModalBodyProps } from './ModalBody';

export const Element = styled.div<ModalBodyProps>`
  ${({ theme }) => {
    return css`
      background: ${theme.color.white};
      padding: var(--modal-spacing);

      max-height: calc(100vh - 15rem);
      overflow-y: scroll;
    `;
  }};
`;
